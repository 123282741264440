import React from 'react'
import { useParams } from 'react-router-dom'
import NotFound from '../NotFound/NotFound'
import HackathonProject from './HackathonProject'
import MarchMadnessProject from './MarchMadnessProject'

const ProjectRouter = ({ setTitle }) => {
    const { name } = useParams()
    switch(name) {
        case "hackathon":
            return <HackathonProject setTitle={setTitle}/>
        case "marchmadness":
            return <MarchMadnessProject setTitle={setTitle}/>
        default:
            return <NotFound />
    }
}

export default ProjectRouter