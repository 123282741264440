import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import Layout from "./Layout/Layout";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import CV from "./Pages/CV/CV";
import Projects from "./Pages/Projects/Projects";
import ProjectRouter from "./Pages/Projects/ProjectRouter";
import Contact from "./Pages/Contact/Contact";
import NotFound from "./Pages/NotFound/NotFound";

function App() {
  const [title, setTitle] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalElement, setModalElement] = useState(1);

  useEffect(() => {
    document.title = title === "" ? "Logan Millet" : title;
  }, [title]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Layout
              title={title}
              modalOpen={modalOpen}
              modalElement={modalElement}
            />
          }
        >
          <Route index element={<Home setTitle={setTitle} />} />
          <Route path="about" element={<About setTitle={setTitle} />} />
          <Route path="cv" element={<CV />} />
          <Route path="projects">
            <Route
              index
              element={
                <Projects
                  setTitle={setTitle}
                  setModalOpen={setModalOpen}
                  setModalElement={setModalElement}
                />
              }
            />
            <Route
              path=":name"
              element={<ProjectRouter setTitle={setTitle} />}
            />
          </Route>
          <Route path="contact" element={<Contact setTitle={setTitle} />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
