import React, { useEffect } from "react";
import "./About.css";
import img from "../../images/About/Close-up Angled.jpg";

const About = ({ setTitle }) => {
  useEffect(() => {
    setTitle("About Me");
  }, [setTitle]);

  return (
    <div className="about__main" style={{ flexGrow: 1 }}>
      <div className="about__links">
        <ul>
          <li>
            <a href="#PreCollege">Pre-College</a>
          </li>
          <li>
            <a href="#Switching">Switching to CS</a>
          </li>
          <li>
            <a href="#Interests_Goals">Interests and Goals</a>
          </li>
        </ul>
        <br />
        <br />
      </div>
      <div className="image">
        <img
          src={img}
          alt="personal-photo"
          width="25%"
          style={{
            float: "right",
            borderRadius: "0.5em",
            minHeight: "285px",
            minWidth: "189.5px",
            margin: "10px",
            maxWidth: "287.25px",
            maxHeight: "432.02px",
          }}
        />
      </div>
      <div id="PreCollege" className="PreCollege">
        <h2>Pre-College</h2>
        <p>
          I am a junior at the University of Alabama studying Computer Science
          and Mathematics with a minor in Aerospace Engineering. Born and raised
          in New Orleans, I’ve been coding from a young age. Throughout high
          school, I worked on small side projects for fun, but never really saw
          software engineering as a career possibility. Being more attracted to
          physics and math, I thought engineering was for me.
        </p>
        <br />
      </div>
      <div id="Switching" className="Switching">
        <h2>Switching to CS</h2>
        <p>
          I received a National Merit Finalist standing from the National Merit
          Scholarship Corporation, which granted me a full ride to the
          University of Alabama. The summer before I started, however, I was
          graciously offered a position as a software developer intern at the
          Gray Insurance Company. Here, I was put in charge of developing an
          online customer portal using Microsoft’s Blazor for frontend and Java
          for backend. This was my first experience with working on a real
          development team, and I liked it so much I decided to add a Computer
          Science minor to my Aerospace Engineering major.
        </p>
        <br />
        <p>
          When I started college, I enjoyed my aerospace classes, but I enjoyed
          my computer science classes much more. Learning C was challenging but
          exciting, and I began to feel even more enthused about programming.
          The following summer I went back to the Gray Insurance Company, where
          I completed and saw the portal I had worked on the previous summer to
          production as well as worked on a few other projects, such as
          integrating Leadtools technology to upgrade a document viewer and
          building workflows for other employees to use in our internal
          application. By the start of my sophomore year, I had switched to a
          Computer Science major, but still having an interest in aerospace,
          kept an Aerospace Engineering minor.
        </p>
        <br />
      </div>
      <div id="Interests_Goals" className="Interests_Goals">
        <h2>Interests and Goals</h2>
        <p>
          Throughout my sophomore year, I learned C++ in my classes, and
          developed an interest in Artificial Intelligence and python on the
          side. I attempted to build an AI stock portfolio manager from scratch,
          and even though it did not give very helpful results, I learned a ton
          about how machine learning algorithms are built and how artificial
          intelligence in general works, as well as becoming increasingly adept
          in Python. Towards the end of my sophomore year, I decided to add an
          additional mathematics major to supplement my computer science classes
          with a wider base of knowledge.
        </p>
        <br />
        <p>
          My ideal career goal would be to do software engineering work in the
          aerospace industry; however, as I continue my studies, I recognize
          that interests are susceptible to change and am open to any kind of
          work including web development, AI, data analysis, etc.
        </p>
      </div>
    </div>
  );
};

export default About;
