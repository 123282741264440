import "./ProjectTemplate.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

const ProjectTemplate = ({
  title,
  blurb,
  imageSrc,
  internalUrl,
  externalUrl,
  setModalOpen,
  setModalElement,
}) => {
  const openLink = (e, url) => {
    e.preventDefault();
    window.open(url, "_blank");
  };
  const navigate = useNavigate();
  return (
    <div className="project-card">
      <img src={imageSrc} alt={title} width="100%" className="project-image" />
      <h2>{title}</h2>
      <p className="project-blurb">{blurb}</p>
      <div className="project-card__buttons large">
        <button
          className="external-link-button"
          onClick={() => {
            // setModalOpen(true);
            // setModalElement(modalElement);
            navigate(internalUrl);
          }}
        >
          <FontAwesomeIcon icon={faCircleInfo} />
          <span>See More</span>
        </button>
        <button
          className="external-link-button"
          onClick={(e) => openLink(e, externalUrl)}
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          <span>View Project</span>
        </button>
      </div>
    </div>
  );
};

export default ProjectTemplate;
