import React, { useEffect } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import {
  faUser,
  faFileCode,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import "./Home.css";

const Home = ({ setTitle }) => {
  useEffect(() => {
    setTitle("");
  }, [setTitle]);

  useGSAP(() => {
    const tl = gsap.timeline();
    tl.from(".title__top", { duration: 1.5, x: "-100vw", ease: "back.out(1)" });
    tl.from(
      ".title__bottom",
      { duration: 1.5, x: "100vw", ease: "back.out(1)" },
      "<"
    );
    tl.from(".home-button", {
      duration: 1.5,
      y: "100vh",
      opacity: 0,
      ease: "back.out(1)",
      stagger: 0.1,
    });
  });

  const navigate = useNavigate();

  return (
    <div className="home__main">
      <div className="title">
        <div className="title-text">
          <div className="title__top" style={{ left: 0 }}>
            <span>LOGAN</span>
          </div>
          <div className="title__bottom">
            <span>MILLET</span>
          </div>
        </div>
      </div>
      <div className="home-buttons">
        <div className="home-button">
          <button
            className="external-link-button"
            onClick={() => navigate("/about")}
          >
            <div className="icon-button">
              <FontAwesomeIcon icon={faUser} />
              <span>About Me</span>
            </div>
          </button>
        </div>
        <div className="home-button">
          <button
            className="external-link-button"
            onClick={() => navigate("/projects")}
          >
            <div className="icon-button">
              <FontAwesomeIcon icon={faFileCode} />
              <span>Projects</span>
            </div>
          </button>
        </div>
        <div className="home-button">
          <button
            className="external-link-button"
            onClick={() => navigate("/contact")}
          >
            <div className="icon-button">
              <FontAwesomeIcon icon={faAddressBook} />
              <span>Contact</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
