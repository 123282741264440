import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import './footer.css'

const Footer = () => {
  return (
    <footer>
      <div className='footer__contact'>
        <FontAwesomeIcon icon={faEnvelope} />
        <a href="mailto:loganmillet38@gmail.com">loganmillet38@gmail.com</a>
      </div>
      <div className='footer__contact'>
        <FontAwesomeIcon icon={faPhone} />
        <a href='tel:+15043279235'>(504) 327-9235</a>
      </div>
    </footer>
  )
}

export default Footer