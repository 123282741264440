import {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { faBars, faHome, faUser, faFileCode, faAddressBook } from '@fortawesome/free-solid-svg-icons'
import './header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Header = ({ title, sideMenuOpen, setSideMenuOpen }) => {
  const [sideMenuClass, setSideMenuClass] = useState('side-menu-container')

  useEffect(() => {
    if(sideMenuOpen) {
      setSideMenuClass('side-menu-container open')
    } else {
      setSideMenuClass('side-menu-container')
    }
  }, [sideMenuOpen])

  const linkHeaders = [
    {
      url:"/",
      name:"Home",
      icon:faHome
    },
    {
      url:"/about",
      name:"About Me",
      icon:faUser
    },
    {
      url:"/projects",
      name:"Projects",
      icon:faFileCode
    },
    {
      url:"/contact",
      name:"Contact",
      icon:faAddressBook
    }
  ]



  const linkElements = linkHeaders.map(link => (<Link to={link.url} key={link.name} onClick={() => setSideMenuOpen(false)}><FontAwesomeIcon icon={link.icon}/>{link.name}</Link>))
  return (
    <header>
      
      <div className={sideMenuClass}>
        <FontAwesomeIcon icon={faBars} className='side-menu-btn' onClick={() => {setSideMenuOpen(!sideMenuOpen)}}/>
        <div className='side-menu'>
          {linkElements}
        </div>
      </div>
      <h1 className='header__title'>{title.toUpperCase()}</h1>
      {linkElements}
    </header>
  )
}

export default Header