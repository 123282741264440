import ProjectTemplate from "./ProjectTemplate";
import "./Projects.css";
import HackathonTitle from "./HackathonTitle.jpg";
import MMAITitle from "./MarchMadness.jpg";
import { useEffect } from "react";

const Projects = ({ setTitle, setModalOpen, setModalElement }) => {
  const projects = [
    {
      name: "hackathon",
      title: "UA Innovate Hackathon",
      blurb:
        "A full stack project that I, and a group of 3 others, worked on and submitted to the 2024 UA Innovate Hackathon, hosted by the University of Alabama",
      imageSrc: HackathonTitle,
      internalUrl: "/projects/hackathon",
      externalUrl: "https://github.com/TheGoldenPatrik1/ua-innovate",
    },
    {
      name: "marchmadnessai",
      title: "March Madness AI",
      blurb:
        "A machine learning algorithm to gather data and predict who will win the March Madness tournament",
      imageSrc: MMAITitle,
      internalUrl: "/projects/marchmadness",
      externalUrl: "https://github.com/TheGoldenPatrik1/ua-innovate",
    },
  ];

  useEffect(() => {
    setTitle("Projects");
  }, [setTitle]);

  const projectTemplates = projects.map((project) => (
    <ProjectTemplate
      key={project.name}
      title={project.title}
      blurb={project.blurb}
      imageSrc={project.imageSrc}
      internalUrl={project.internalUrl}
      externalUrl={project.externalUrl}
      setModalOpen={setModalOpen}
      setModalElement={setModalElement}
    />
  ));
  return (
    <div>
      <div className="projects-container">{projectTemplates}</div>
    </div>
  );
};

export default Projects;
