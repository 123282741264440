import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import "./layout.css";
import { useEffect, useState } from "react";
import Modal from "../Components/Modal";
const Layout = ({ title, modalOpen, elementId }) => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [overlayClass, setOverlayClass] = useState("overlay");

  useEffect(() => {
    setOverlayClass(sideMenuOpen ? "overlay show" : "overlay");
  }, [sideMenuOpen]);

  useEffect(() => {
    setOverlayClass(modalOpen ? "overlay show" : "overlay");
  }, [modalOpen]);

  const handleOverlayClick = () => {
    setSideMenuOpen(false);
  };
  return (
    <div className="layout">
      <div className={overlayClass} onClick={handleOverlayClick}></div>
      <Header
        title={title}
        sideMenuOpen={sideMenuOpen}
        setSideMenuOpen={setSideMenuOpen}
      />
      <main>
        <Outlet />
        {modalOpen && <Modal elementId={elementId} />}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
