import React, { useEffect, useState } from "react";
import HackathonProject from "../Pages/Projects/HackathonProject";
import MarchMadnessProject from "../Pages/Projects/MarchMadnessProject";

const Modal = ({ elementId }) => {
  const [ModalElement, setModalElement] = useState(<div></div>);

  useEffect(() => {
    switch (elementId) {
      case 1:
        setModalElement(<HackathonProject />);
        break;
      case 2:
        setModalElement(<MarchMadnessProject />);
        break;
      default:
        setModalElement(<div></div>);
        break;
    }
  }, [elementId]);
  return (
    <div>
      <ModalElement />
    </div>
  );
};

export default Modal;
