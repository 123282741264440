import React from "react";

const MarchMadnessProject = ({ setTitle }) => {
  setTitle("March Madness AI");
  return (
    <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <div style={{ flexGrow: 1 }}>
        <h2 className="subheading">About Project</h2>
        <p>
          This project is currently a work in progress, but its aim is to be
          able to use Artifical Intelligence and Machine Learning to predict the
          NCAA March Madness bracket to the highest degree of accuracy possible.
          My goal is to run simulations on years past and have above a 50% win
          rate on the PickHoops league I am currently in, which is a standard
          30-50 player league. This provides a concrete, challenging, yet I
          think achievable, goal given the size of my league.
        </p>
        <br />
        <p>
          Scoring in the league is based as follows: for each round in the
          tournament, the number of points earned for each correct match pick
          goes up by 1. For instance, correctly guessing 25/32 matches in the
          first round (Round of 64) will earn 25 points. However, guessing 10/16
          matches correctly in the second round (Round of 32) will earn 20
          points. This pattern repeats until the championship, where correctly
          guessing the champion will earn 10 additional points. This system
          favors correctly guessing later round picks, especially the
          championship, over earlier round picks, which affects how I set up the
          data I train my algorithm with.
        </p>
        <br />
        <p>
          I collected the season, last three games, and last game average of 27
          different stats from all 64 teams that played in the tournament. I
          then assigned a score based on how far they made in the tournament
          that was equivalent to the sum of points earned had I picked them all
          the way to when they lost. This way, teams that go far are heavily
          favored over teams that lose in the first two rounds. The major issue
          with this is that upsets greatly skew the value for how good a team
          is.
        </p>
        <br />
        <br />
        <h2 className="subheading">Tech Specs</h2>
        <p>
          This project was built in python primarily and uses several libraries
          for the different tasks. To scrape the data off{" "}
          <a
            href="https://www.teamrankings.com/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline" }}
          >
            teamrankings.com
          </a>
          , I used Scrapy. I then used Scikit Learn for the machine learning
          algorithms with pipelines and transformations to attempt to find
          trends in data.
        </p>
        <br />
        <br />
        <h2 className="subheading">Future Goals</h2>
        <p>
          Because my goal is not yet reached, I am working on creating a more
          expansive database, and I will try to use time-series analysis over a
          season. This will hopefully give me more accurate results as I track
          team statistics over the course of the season, rather than just the
          end of the season.
        </p>
      </div>
    </div>
  );
};

export default MarchMadnessProject;
