import React from "react";
// import PictureGallery from "../../Components/PictureGallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import "./HackathonProject.css";

const HackathonProject = ({ setTitle }) => {
  setTitle("UA Innovate 2024");
  // const pictureCaptions = [
  //   "My team and I winning the Full Stack Development Category",
  //   "Sample code",
  //   "React logo",
  // ];

  const handleLinkClicked = (e) => {
    e.preventDefault();
    window.open("https://github.com/TheGoldenPatrik1/ua-innovate", "_blank");
  };
  return (
    <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <div style={{ flexGrow: 1 }}>
        <h2 className="subheading">About Project</h2> <br />
        <p>
          The University of Alabama hosted its third hackathon named UA
          Innovate. This is an event where people split up into groups of four
          maximum and work to compete in a given category, such as
          Cybersecurity, Social Innovation, Fintech, and Full Stack Development.
          Each team has 24 hours to create their solution from scratch and
          submit it to the judges. This is the project for my team's full stack
          submission, which won 1st place.
        </p>
        <br />
        <p>
          We created a web portal for both students and HR representatives to
          streamline applications and make interactions at job fairs easier for
          both students and HR at the category's sponsor, CGI. Our portal
          includes a login for HR and allows them to sort and search for
          candidates; view, edit, and delete candidate profiles; and evaluate
          candidates. Students can also access the portal to create and edit
          their profile to be evaluated. Additionally, students can upload their
          resumes, which makes viewing and saving digital resumes much easier
          for the company representative. Lastly, HR representatives can create
          reports on a filtered set of student candidates to get a high-level
          overview on ratings and evaluations for each student.
        </p>
        <br />
        <p>
          Special thanks to teammates Malachi Crain, Arden Markin, and Samuel
          Gaines as well as judges from CGI Grant Logsdon and Jacob Harrell!
        </p>
        <br />
        <br />
        {/* <div style={{display:"flex", justifyContent:"center"}}>
            <PictureGallery folderPath="/MarchMadness/" height="400px" width="600px" captions={pictureCaptions}/>
        </div> */}
        <h2 className="subheading">Tech Specs</h2> <br />
        <p>
          This project was built with a front end using React.js, a backend
          using Express.js, and MongoDB as a database. I personally worked on
          the front end side of the project, specifically the HR portal,
          implementing designs, filtering, and sorting functions. It allows for
          HR representatives to quickly and efficiently see the top students
          from a given event.
        </p>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button className="external-link-button" onClick={handleLinkClicked}>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          <span>View Code</span>
        </button>
      </div>
    </div>
  );
};

export default HackathonProject;
